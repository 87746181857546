//  -1 -2 -3 -4 -5 -6 -7
//  C3 D3 E3 F3 G3 A3 B3

//   1  2  3  4  5  6  7
//  C4 D4 E4 F4 G4 A4 B4

//  +1 +2 +3 +4 +5 +6 +7
//  C5 D5 E5 F5 G5 A5 B5

const song = [
    '3', '2', '1.', '1', '1.', '2', '3', '4', '3.', '4', '2', '1', '2', '2', '-7', '-7', '1', '1', '-6', '-6', '-7', '1', '1', '2'
]

export default [...song]
