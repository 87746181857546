export default {
  A2: { url: "./samples/piano/a54.mp3", isPlay: false },
  A3: { url: "./samples/piano/a69.mp3", isPlay: false },
  A4: { url: "./samples/piano/a80.mp3", isPlay: false },
  A5: { url: "./samples/piano/a74.mp3", isPlay: false },
  A6: { url: "./samples/piano/a66.mp3", isPlay: false },
  'A#3': { url: "./samples/piano/b69.mp3", isPlay: false },
  'A#4': { url: "./samples/piano/b80.mp3", isPlay: false },
  'A#5': { url: "./samples/piano/b74.mp3", isPlay: false },
  'A#6': { url: "./samples/piano/b66.mp3", isPlay: false },
  B2: { url: "./samples/piano/a55.mp3", isPlay: false },
  B3: { url: "./samples/piano/a82.mp3", isPlay: false },
  B4: { url: "./samples/piano/a65.mp3", isPlay: false },
  B5: { url: "./samples/piano/a75.mp3", isPlay: false },
  B6: { url: "./samples/piano/a78.mp3", isPlay: false },
  C2: { url: "./samples/piano/a49.mp3", isPlay: false },
  C3: { url: "./samples/piano/a56.mp3", isPlay: false },
  C4: { url: "./samples/piano/a84.mp3", isPlay: false },
  C5: { url: "./samples/piano/a83.mp3", isPlay: false },
  C6: { url: "./samples/piano/a76.mp3", isPlay: false },
  C7: { url: "./samples/piano/a77.mp3", isPlay: false },
  'C#2': { url: "./samples/piano/b49.mp3", isPlay: false },
  'C#3': { url: "./samples/piano/b56.mp3", isPlay: false },
  'C#4': { url: "./samples/piano/b84.mp3", isPlay: false },
  'C#5': { url: "./samples/piano/b83.mp3", isPlay: false },
  'C#6': { url: "./samples/piano/b76.mp3", isPlay: false },
  D2: { url: "./samples/piano/a50.mp3", isPlay: false },
  D3: { url: "./samples/piano/a57.mp3", isPlay: false },
  D4: { url: "./samples/piano/a89.mp3", isPlay: false },
  D5: { url: "./samples/piano/a68.mp3", isPlay: false },
  D6: { url: "./samples/piano/a90.mp3", isPlay: false },
  'D#2': { url: "./samples/piano/b50.mp3", isPlay: false },
  'D#3': { url: "./samples/piano/b57.mp3", isPlay: false },
  'D#4': { url: "./samples/piano/b89.mp3", isPlay: false },
  'D#5': { url: "./samples/piano/b68.mp3", isPlay: false },
  'D#6': { url: "./samples/piano/b90.mp3", isPlay: false },
  E2: { url: "./samples/piano/a51.mp3", isPlay: false },
  E3: { url: "./samples/piano/a48.mp3", isPlay: false },
  E4: { url: "./samples/piano/a85.mp3", isPlay: false },
  E5: { url: "./samples/piano/a70.mp3", isPlay: false },
  E6: { url: "./samples/piano/a88.mp3", isPlay: false },
  F2: { url: "./samples/piano/a52.mp3", isPlay: false },
  F3: { url: "./samples/piano/a81.mp3", isPlay: false },
  F4: { url: "./samples/piano/a73.mp3", isPlay: false },
  F5: { url: "./samples/piano/a71.mp3", isPlay: false },
  F6: { url: "./samples/piano/a67.mp3", isPlay: false },
  'F#2': { url: "./samples/piano/b52.mp3", isPlay: false },
  'F#3': { url: "./samples/piano/b81.mp3", isPlay: false },
  'F#4': { url: "./samples/piano/b73.mp3", isPlay: false },
  'F#5': { url: "./samples/piano/b71.mp3", isPlay: false },
  'F#6': { url: "./samples/piano/b67.mp3", isPlay: false },
  G2: { url: "./samples/piano/a53.mp3", isPlay: false },
  G3: { url: "./samples/piano/a87.mp3", isPlay: false },
  G4: { url: "./samples/piano/a79.mp3", isPlay: false },
  G5: { url: "./samples/piano/a72.mp3", isPlay: false },
  G6: { url: "./samples/piano/a86.mp3", isPlay: false },
  'G#2': { url: "./samples/piano/b53.mp3", isPlay: false },
  'G#3': { url: "./samples/piano/b87.mp3", isPlay: false },
  'G#4': { url: "./samples/piano/b79.mp3", isPlay: false },
  'G#5': { url: "./samples/piano/b72.mp3", isPlay: false },
  'G#6': { url: "./samples/piano/b86.mp3", isPlay: false }
}