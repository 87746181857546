const song = [
    {// 月亮代表我的心
        // -5
        note: 'G3',
        time: 500
    }, {
        // 1.
        note: 'C4',
        time: 1000
    }, {
        // 3
        note: 'E4',
        time: 500
    }, {
        // 5.
        note: 'G4',
        time: 1000
    }, {
        // 1
        note: 'C4',
        time: 500
    }, {
        // -7
        note: 'B3',
        time: 1000
    }, {
        // 3
        note: 'E4',
        time: 500
    }, {
        // 5
        // 0
        // 我
        note: 'G4',
        time: 1500
    }, {
        // 5
        note: 'G4',
        time: 500
    }, {
        // 6.
        note: 'A4',
        time: 1000
    }, {
        // 7
        note: 'B4',
        time: 500
    }, {
        // +1.
        note: 'C5',
        time: 1000
    }, {
        // 6
        note: 'A4',
        time: 500
    }, {
        // 5
        note: 'G4',
        time: 1500
    }, {
        // 3
        note: 'E4',
        time: 500
    }, {
        // 2
        note: 'D4',
        time: 500
    }, {
        // 1.
        note: 'C4',
        time: 1000
    }, {
        // 1
        note: 'C4',
        time: 500
    }, {
        // 1
        note: 'C4',
        time: 500
    }, {
        // 3
        // 我的
        note: 'E4',
        time: 500
    }, {
        // 2
        note: 'D4',
        time: 500
    }, {
        // 1
        note: 'C4',
        time: 1000
    }, {
        // 1
        note: 'C4',
        time: 500
    }, {
        // 1
        note: 'C4',
        time: 500
    }, {
        // 2
        // 月亮
        note: 'D4',
        time: 500
    }, {
        // 3
        note: 'E4',
        time: 500
    }, {
        // 2
        note: 'D4',
        time: 1000
    }, {
        // 1
        note: 'C4',
        time: 500
    }, {
        // -6
        note: 'A3',
        time: 500
    }, {
        // 2
        note: 'D4',
        time: 500
    }, {
        // 3
        note: 'E4',
        time: 500
    }, {
        // 2
        note: 'D4',
        time: 1500
    }, {// 月亮代表我的心
        // -5
        note: 'G3',
        time: 500
    }, {
        // 1.
        note: 'C4',
        time: 1000
    }, {
        // 3
        note: 'E4',
        time: 500
    }, {
        // 5.
        note: 'G4',
        time: 1000
    }, {
        // 1
        note: 'C4',
        time: 500
    }, {
        // -7
        note: 'B3',
        time: 1000
    }, {
        // 3
        note: 'E4',
        time: 500
    }, {
        // 5
        // 0
        // 我
        note: 'G4',
        time: 1500
    }, {
        // 5
        note: 'G4',
        time: 500
    }, {
        // 6.
        note: 'A4',
        time: 1000
    }, {
        // 7
        note: 'B4',
        time: 500
    }, {
        // +1.
        note: 'C5',
        time: 1000
    }, {
        // 6
        note: 'A4',
        time: 500
    }, {
        // 5
        note: 'G4',
        time: 1500
    }, {
        // 3
        note: 'E4',
        time: 500
    }, {
        // 2
        note: 'D4',
        time: 500
    }, {
        // 1.
        note: 'C4',
        time: 1000
    }, {
        // 1
        note: 'C4',
        time: 500
    }, {
        // 1
        note: 'C4',
        time: 500
    }, {
        // 3
        // 我的
        note: 'E4',
        time: 500
    }, {
        // 2
        note: 'D4',
        time: 500
    }, {
        // 1
        note: 'C4',
        time: 1000
    }, {
        // 1
        note: 'C4',
        time: 500
    }, {
        // 1
        note: 'C4',
        time: 500
    }, {
        // 2
        // 月亮
        note: 'D4',
        time: 500
    }, {
        // 3
        note: 'E4',
        time: 500
    }, {
        // 2
        note: 'D4',
        time: 1000
    }, {
        // 1
        note: 'C4',
        time: 500
    }, {
        // -6
        note: 'A3',
        time: 500
    }, {
        // 2
        note: 'D4',
        time: 500
    }, {
        // 3
        note: 'E4',
        time: 500
    }, {
        // 2
        note: 'D4',
        time: 1500
    },

    {
        // 轻轻地一个吻
        // 3
        note: 'E4',
        time: 500
    }, {
        // 5
        note: 'G4',
        time: 500
    }, {
        // 3
        note: 'E4',
        time: 1000
    }, {
        // 2
        note: 'D4',
        time: 500
    }, {
        // 1
        note: 'C4',
        time: 500
    }, {
        // 5
        note: 'G4',
        time: 500
    }, {
        // -7
        note: 'B3',
        time: 1500
    }, {
        // -6
        note: 'A3',
        time: 500
    }, {
        // -7
        note: 'B3',
        time: 500
    }, {
        // -6
        note: 'A3',
        time: 1000
    }, {
        // -7
        note: 'B3',
        time: 500
    }, {
        // -6
        note: 'A3',
        time: 500
    }, {
        // -5
        note: 'G3',
        time: 500
    }, {
        // 3
        note: 'E4',
        time: 1500
    }, {
        // 5
        note: 'G4',
        time: 500
    }, {
        // 3.
        note: 'E4',
        time: 1000
    }, {
        // 2
        note: 'D4',
        time: 500
    }, {
        // 1
        note: 'C4',
        time: 500
    }, {
        // 5
        note: 'G4',
        time: 500
    }, {
        // -7...
        note: 'B3',
        time: 1500
    }, {
        // -6
        note: 'A3',
        time: 500
    }, {
        // -7
        note: 'B3',
        time: 500
    }, {
        // 1.
        note: 'C4',
        time: 1000
    }, {
        // 1
        note: 'C4',
        time: 500
    }, {
        // 1
        note: 'C4',
        time: 500
    }, {
        // 2
        note: 'D4',
        time: 500
    }, {
        // 3.
        note: 'E4',
        time: 500
    }, {
        // 2
        note: 'D4',
        time: 1500
    },
    // 你问我
    {
        // -5
        note: 'G3',
        time: 500
    }, {
        // 1.
        note: 'C4',
        time: 1000
    }, {
        // 3
        note: 'E4',
        time: 500
    }, {
        // 5.
        note: 'G4',
        time: 1000
    }, {
        // 1
        note: 'C4',
        time: 500
    }, {
        // -7
        note: 'B3',
        time: 1000
    }, {
        // 3
        note: 'E4',
        time: 500
    }, {
        // 5
        // 0
        // 我
        note: 'G4',
        time: 1500
    }, {
        // 5
        note: 'G4',
        time: 500
    }, {
        // 6.
        note: 'A4',
        time: 1000
    }, {
        // 7
        note: 'B4',
        time: 500
    }, {
        // +1.
        note: 'C5',
        time: 1000
    }, {
        // 6
        note: 'A4',
        time: 500
    }, {
        // 5
        note: 'G4',
        time: 1500
    }, {
        // 3
        note: 'E4',
        time: 500
    }, {
        // 2
        note: 'D4',
        time: 500
    }, {
        // 1.
        note: 'C4',
        time: 1000
    }, {
        // 1
        note: 'C4',
        time: 500
    }, {
        // 1
        note: 'C4',
        time: 500
    }, {
        // 3
        // 我的
        note: 'E4',
        time: 500
    }, {
        // 2
        note: 'D4',
        time: 500
    }, {
        // 1
        note: 'C4',
        time: 1000
    }, {
        // 1
        note: 'C4',
        time: 500
    }, {
        // 1
        note: 'C4',
        time: 500
    }, {
        // 2
        // 月亮
        note: 'D4',
        time: 500
    }, {
        // 3
        note: 'E4',
        time: 500
    }, {
        // 2
        note: 'D4',
        time: 1000
    }, {
        // -6
        note: 'A3',
        time: 1000
    }, {
        // -7
        note: 'B3',
        time: 500
    }, {
        // 1
        note: 'C4',
        time: 1000
    }, {
        // 2
        note: 'D4',
        time: 500
    }, {
        // 1
        note: 'C4',
        time: 1000
    }
]
export default [...song, ...song]