//  -1 -2 -3 -4 -5 -6 -7
//  C3 D3 E3 F3 G3 A3 B3

//   1  2  3  4  5  6  7
//  C4 D4 E4 F4 G4 A4 B4

//  +1 +2 +3 +4 +5 +6 +7
//  C5 D5 E5 F5 G5 A5 B5

const song = [
    '-5', '-6', '1', '2', '1', '2', '3',
    '3', '3', '2', '1', '2', '3',
    '3', '3', '2', '1', '2.', '1', '1', '-6', '1.', '2', '2', '3.',
    '-5', '-6', '1', '2', '1', '2', '1', '1', '5',
    '5', '3', '2', '3', '2', '1', '1', '3',
    // 今天想着你回家
    '3', '2', '1', '2.', '2', '2.', '1', '1', '-6', '2.',
    // 原谅我不再送花
    '-5', '-6', '1', '2', '1', '2', '3', '3', '5',
    // 伤口应要结疤
    '5', '3', '2', '1', '2', '3', '3', '6',
    // 花
    '6', '3', '2', '1', '2.', '1', '1', '6', '1.', '2', '2', '3', '5.',
    // 如若你非我不嫁
    '-5', '-6', '1', '2', '1', '2', '1', '1',
    // 彼此
    '6', '6', '5', '3', '3', '2', '1',
    // 一生一世
    '1', '3', '3', '3', '2', '1', '2.', '2', '2.', '1', '1', '-6', '1',
    // 谁都只得
    '1', '+1', '7', '+1', '6', '+1.', '6', '6', '5', '6', '5', '3', '2', '3', '5', '6', '5',
    // 要拥有
    '5', '5', '6', '5', '6.', '6', '6.', '5', '5', '6', '5', '3', '3',
    // 曾沿着
    '1', '2', '3', '5', '3', '2', '1', '1', '1', '2', '3', '6', '3', '2', '1',
    // 谁能凭爱意要富士山私有
    '1', '1', '+1.', '+1', '+1.', '6', '6', '+1', '6.', '5',
    // 何不把
    '5', '1', '+1', '7', '+1', '7', '6', '5', '6', '5', '3', '2', '3', '5', '6', '5',
    // 试管里找不到
    '5', '5', '6', '5', '6.', '6', '6.', '5', '5', '6', '5', '3', '3',
    // 前尘
    '1', '2', '3', '5', '3', '2', '1', '1', '1', '2', '3', '6', '3', '2', '1', '+1..',
    // 我绝不
    '+1', '6', '+1', '6', '5', '5', '5', '6', '5', '3', '2', '3', '2', '2', '1', '-6', '1', '1', '2',
    // 你好嫌不够
    '1', '1', '+1', '6', '+1', '+2', '+1', '+1', '+1', '+2', '+1', '6', '+1', '+3', '+2', '+2', '+1', '6', '+1', '+2', '1'
]

export default [...song, ...song]
