export default {
    // A
    A2: "Shift+6",
    A3: "Ctrl+6",
    A4: "6",
    A5: "Option+6",
    A6: "Command+6",
    'A#3': "Ctrl+t",
    'A#4': "t",
    'A#5': "Option+t",
    'A#6': "Command+t",
    // B
    B2: "Shift+7",
    B3: "Ctrl+7",
    B4: "7",
    B5: "Option+7",
    B6: "Command+7",
    // C
    C2: "Shift+1",
    C3: "Ctrl+1",
    C4: "1",
    C5: "Option+1",
    C6: "Command+1",
    C7: "",
    'C#2': "Shift+q",
    'C#3': "Ctrl+q",
    'C#4': "q",
    'C#5': "Option+q",
    'C#6': "Command+q",
    // D
    D2: "Shift+2",
    D3: "Ctrl+2",
    D4: "2",
    D5: "Option+2",
    D6: "Command+2",
    'D#2': "Shift+w",
    'D#3': "Ctrl+w",
    'D#4': "w",
    'D#5': "Option+w",
    'D#6': "Command+w",
    // E
    E2: "Shift+3",
    E3: "Ctrl+3",
    E4: "3",
    E5: "Option+3",
    E6: "Command+3",
    'E#2': "Shift+e",
    'E#3': "Ctrl+e",
    'E#4': "e",
    'E#5': "Option+e",
    'E#6': "Command+e",
    // F
    F2: "Shift+4",
    F3: "Ctrl+4",
    F4: "4",
    F5: "Option+4",
    F6: "Command+4",
    'E#2': "Shift+e",
    'E#3': "Ctrl+e",
    'E#4': "e",
    'E#5': "Option+e",
    'E#6': "Command+e",
    // G
    G2: "Shift+5",
    G3: "Ctrl+5",
    G4: "5",
    G5: "Option+5",
    G6: "Command+5",
    'G#2': "./samples/piano/b53.mp3",
    'G#3': "./samples/piano/b87.mp3",
    'G#4': "./samples/piano/b79.mp3",
    'G#5': "./samples/piano/b72.mp3",
    'G#6': "./samples/piano/b86.mp3"
}