// const song = [
//     '3', '4',
//     '5', '5', '5', '6', '7', '+1..',
//     '+1', '+1', '7', '+2', '6', '5', '5', '5', '+2', '+1', '+1', '+3', '+3..',
//     '+1', '+2', '+3', '+3', '+4', '+3', '+2', '+3', '+1', '+1', '6', '6',
//     '6', '7', '+1', '+2', '+2', '+1', '7', '6', '+4', '+2',
//     // 将愿望
//     '+2..', '3', '4', '5',
//     // 折飞机寄成信
//     '5', '5', '5', '6', '7', '+1..',
//     '+1', '+1', '7', '+2', '6', '5', '5', '5', '+2', '+1', '+1', '+3', '+3..',
//     '+1', '+2', '+3', '+3', '+4', '+3', '+2', '+3', '+1', '+1', '6', '6',
//     '6', '7', '+1', '+2', '+2', '+1', '7', '6', '+4', '+2..',
//     // 一起长大的约定
//     '3', '5', '+1', '+3', '+3.', '+4', '+2..', '+2', '+5', '7', '+1..',
//     '+3', '+4', '+5', '+1', '+1', '+2', '+3', '+3..',
//     // 说好要一起旅行
//     '3', '5', '+1.', '+3', '+3.', '+4', '+2..',
//     // 是你如今
//     '+2', '+5', '7', '+1..',
//     // 唯一坚持的任性
//     '+3', '+4', '+5', '+1', '+1', '+2.', '+1', '+1',
//     // '+2', '+1', '7', '+1', '+3', '+2', '+1', '+2', '+3', '+2', '+4', '+3', '+2', '+1', '7', '+1', '+2', '+1', '7', '+1', '+3', '+2', '+1', '+2', '+3', '+2', '+4', '+3', '+1..',
//     // 在走廊
//     '3', '4',
//     '5', '5', '5', '6', '7', '+1..',
//     '+1', '+1', '7', '+2', '6', '5', '5', '5', '+2', '+1', '+1', '+3', '+3..',
//     '+1', '+2', '+3', '+3', '+4', '+3', '+2', '+3', '+1', '+1', '6', '6',
//     '6', '7', '+1', '+2', '+2', '+1', '7', '6', '+4', '+2',

//     // 一起长大的约定
//     '3', '5', '+1', '+3', '+3.', '+4', '+2..', '+2', '+5', '7', '+1..',
//     '+3', '+4', '+5', '+1', '+1', '+2', '+3', '+3..',
//     // 说好要一起旅行
//     '3', '5', '+1.', '+3', '+3.', '+4', '+2..',
//     // 是你如今
//     '+2', '+5', '7', '+1..',
//     // 唯一坚持的任性
//     '+3', '+4', '+5', '+1', '+1', '+2.', '+1', '+1',


//     // 一起长大2
//     '+6', '+5', '+3', '+2', '+1', '+3.', '+4', '+2..',
//     '+6', '+5', '7', '+1..',
//     // 与你聊不完的曾经
//     '+3', '+4', '+5', '+1', '+1', '+2', '+3', '+3..',
//     // 而我已经分不清
//     '3', '5', '+1', '+3', '+3.', '+2', '+2', '+2..', '+2', '+5', '7', '+2', '+1', '+1',
//     // 还是错过的爱情
//     '+3', '+4', '+5', '+1', '+1', '+2.', '+1', '+1..'
// ]
// export default [...song]

const song = [
    '3', '5', '+1', '+3', '+3.6.3.-4', '1', '3', '+4', '+274-4', '-7', '2',
    '+25-3', '-7', '+52', '7', '+13--6', '-3', '-1',
    '+3+14-2', '+4-6', '+51', '+1', '+164--5', '-4', '+22-7', '+353-1', '-5', '1', '2',
    '3-#6-3-1', '5', '+1#41--#4', '+33', '+3.6.3.--4', '-1', '-6', '+4', '+274--4', '-2', '-7',
    '+27--3', '--7', '+5-5', '7', '+13--6', '-3', '1',
    '+3+14-2', '+4-6', '+51', '+1', '+164--5', '-4', '+272-7', '+1#54-1', '-#5',
    '2', '4', '31-5-1', '3', '4', '5', '5-1', '5-5', '53', '5', '--7', '6-5', '72', '+1', '+1--6', '-3', '1',
    '+12-3--5', '7', '+22-7', '6', '6--4', '5-1', '5-6', '+2', '--5', '+1-2', '+3-7', '+3', '41-5-1',
    '31', '-5', '+13-#6-1', '+2', '+3#61', '+3', '+3-3--3', '+3-#5', '+42-7', '+3-3', '+3--#5', '+23-3', '#52', '+3',
    '+2.5.1.--6', '-3', '+1.', '-6', '-3', '641--4', '7-3', '+1-6', '+2-3', '+2--4', '+1-1', '+1-6-4', '+6', '+6--2', '+36--6', '+36-4-2', '+2'
]

const song2 = [
    '+2--5', '-2', '1-5', '-7-5--5', '3', '4', '5---5', '5-1', '5-5', '52', '53', '5--7', '6-5', '7-7', '52', '+2', '+2--6', '+1-3', '1-5', '-3', '+12-3--5', '7', '+22-7', '6', '6--4', '5-1',
    '5-6', '+2--4', '+2--5', '+1-2', '+3-7', '+3-2', '+3-1', '-5', '31', '-5', '+1#63--1', '+2', '+3+11-#6-5', '+35-1', '+3--7---7', '+3-4', '+42-6', '+3---4', '---5', '+3--#5---#5', '+2-3', '2-7', '+3-3',
    '+2.5.1.--6---6', '-3', '+1.', '-6', '-3', '642--5', '7-2', '+15-6-4', '+2-2', '+2--2', '+1--6', '+14-4-2', '6--2', '--3', '--#4', '+3-2', '+362-4', '+2-2',
    '1-6-4.--5.', '1-6', '-4--5', '3-7-4--5', '5', '+14--5---5', '+3', '+3.6.--4', '-1', '-4', '+44-1', '+242--4', '-2', '-7', '-2', '+25--3', '--7', '+5-5-2', '7--7', '+13--6', '-3', '1', '-3', '+314--4', '+4-1', '+5+14-6', '+1--4', '+14-4--5', '-2', '+274-5', '--5',
    '+3+15--1', '-5', '2', '-5', '3-#6--1', '5', '+13---#4', '+3', '+3.6.--4', '-1', '-4', '+44-1', '+242--4', '-2', '-7', '-2', '+25--3', '--7', '+5-5-2', '7--7', '+13--6', '-3', '1', '-3', '+314--4', '+4-1', '+5+14-6', '+1--4', '+14-4--5', '-2', '+274-5', '--5',
    '+2-1--1', '2', '5-3', '+2', '+11-5', '3-3', '+1', '7-1--1', '2', '3-3', '7', '+11-5', '2', '3-3', '+1', '+2--6---6', '2', '5-3', '+2', '+11-5', '3', '5-3', '+1', '7--6---6', '2', '3-3', '+1', '+3--#4', '4', '6-2', '+3', '+22-#4', '4', '6-2', '+2', '+3--2', '4', '6--6', '+3', '+2-4-2', '4', '6--6', '+2', '+47--5', '4', '5-2', '+47', '+37-7-4', '4', '5-2', '+37',
    '+2-1--1', '2', '5-3', '+2', '+11-5', '3-3', '+1', '7-1--1', '2', '3-3', '7', '+11-5', '2', '3-3', '+1', '+2--6---6', '2', '5-3', '+2', '+11-5', '3', '5-3', '+1', '7--6---6', '2', '3-3', '+1', '+3--#4', '4', '6-2', '+3', '+22-#4', '4', '6-2', '+2', '+3--2', '4', '6--6', '+3', '+2-4-2', '4', '6--6',
    '--5---5',
    '51--1', '-1', '51', '-1', '51--1', '3', '4', '5', '+153-1--1', '-#6-5-3', '3-6-5-1--1', '53-6-1--1', '+6+3--#6--1', '+5', '+3--1', '+2-1', '+1--1', '+3.6.--4', '-1', '-4', '+44-1', '+242--4', '-2', '-7', '-2', '+6.', '+2.', '--3', '--7', '+5.7.', '-5-2', '7--7', '+13--6', '-3', '1', '-3',
    '+314', '+314--4', '+4-1', '+5+14-6', '+1--4', '+14-4--5', '-2', '+274-5', '--5',
    '+3+15--1', '-5', '2', '-5', '3-#6--1', '5', '+13---#4', '+3', '+3.6.--4', '-1', '-4', '+44-1', '+242--4', '-2', '-7', '-2', '+25--3', '--7', '+5-5-2', '7--7', '+13--6', '-3', '1', '-3', '+314--4', '+4-1', '+5+14-6', '+1--4', '+14-4--5', '-2', '+274-5',
    '+153--1', '31', '-5--1', '31--1', '5', '+1', '+2', '+3+15-4', '+4-6', '+51', '+13', '+1-5', '-7', '+22', '+1-6', '1', '3', '1', '-7-5', '51', '+13', '+21', '+315-4', '+41', '+53', '+1', '+1-5', '-7', '+22', '+1-1', '-5', '42', '-5', '31--1'

]

export default [...song, ...song2]