import { WeElement, define, h } from 'omi'
import moon from '../app-piano/songs/moon.js'
import keys from './keys.js'

class AppFooter extends WeElement {
  constructor(...args) {
    super(...args)

    this.setSong = song => this.store.setSong(song)
  }

  render(props) {
    return h(
      'div',
      {
        class: 'app-footer'
      },
      h('hr', {
        class: 'mt-5'
      }),
      h(
        'div',
        {
          class: 'row mt-5'
        },
        h(
          'div',
          {
            class: 'col'
          },
          h(
            'div',
            {
              class: 'text-center'
            },
            h(
              'p',
              {
                class: 'mt-4'
              },
              'You can click on the keyboard and play the melody that belongs to you. Here is an example of a piano piece:'
            ),
            h(
              'p',
              null,
              '\u4F60\u53EF\u4EE5\u70B9\u51FB\u952E\u76D8\u4F9D\u987A\u5E8F\u6309\u4EE5\u4E0B\u952E\uFF0C\u63A7\u5236\u597D\u8282\u594F\u6F14\u594F\u5C5E\u4E8E\u4F60\u7684\u65CB\u5F8B\uFF0C\u4E0B\u9762\u662F\u4E00\u9996\u94A2\u7434\u66F2\u7684\u4F8B\u5B50:'
            ),
            h(
              'p',
              {
                class: 'mt-4'
              },
              'Enjoy it!'
            ),
            this.store.data.song.map(item => {
              if (item[0].note) {
                return h(
                  'p',
                  {
                    class: 'mt-3 code'
                  },
                  h(
                    'code',
                    {
                      class: 'p-2 text-dark'
                    },
                    item.map(item2 => {
                      if (item2.note) {
                        const style =
                          this.store.data.count === item2.index
                            ? {
                                '-webkit-text-fill-color': 'red',
                                color: 'red'
                              }
                            : {
                                // '-webkit-text-fill-color': 'black',
                                color: 'black'
                              }
                        return h(
                          'span',
                          {
                            style
                          },
                          this.data.keys[item2.note],
                          ','
                        )
                      }
                    })
                  )
                )
              }
            })
          )
        )
      ),
      h(
        'div',
        {
          class: 'bg-yellow mt-5 py-5'
        },
        h(
          'div',
          {
            class: 'container',
            style: {
              '-webkit-text-fill-color': '#888'
            }
          },
          h(
            'div',
            {
              class: 'do-center'
            },
            h(
              'span',
              {
                class: 'margin-right-8'
              },
              this.data.name
            ),
            this.data.pIcp &&
              h(
                'a',
                {
                  class: 'text-secondary',
                  href: `https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=${this.data.pIcp}`,
                  style: {
                    marginRight: '10px',
                    display: 'inline-flex',
                    alignItems: 'center'
                  }
                },
                h(
                  'strong',
                  null,
                  h(
                    'img',
                    {
                      src:
                        'https://img.alicdn.com/tfs/TB1..50QpXXXXX7XpXXXXXXXXXX-40-40.png',
                      width: 18
                    },
                    h('strong', null, this.data.icp)
                  ),
                  `豫公网安备${this.data.pIcp}`
                )
              ),
            h(
              'a',
              {
                class: 'text-secondary',
                href: 'http://beian.miit.gov.cn/'
              },
              h('strong', null, this.data.icp)
            )
          )
        )
      )
    )
  }

  install() {
    const host = location.host
    let icp = '豫ICP备15012682号'
    let name = '那时秋水'
    let pIcp = null
    switch (host) {
      case 'yuanqianting.com':
      case 'www.yuanqianting.com':
        icp = '豫ICP备15012682号-1'
        name = '那时秋水'
        break
      case '袁倩婷.我爱你':
      case 'xn--xwq04wgw2b.xn--6qq986b3xl':
      case 'www.袁倩婷.我爱你':
      case 'www.xn--xwq04wgw2b.xn--6qq986b3xl':
        icp = '豫ICP备15012682号-3'
        name = '染指青春'
        break
      case '25436.cn':
      case 'www.25436.cn':
        icp = '豫ICP备15012682号-4'
        name = '清霆'
        break
      case 'yqt.life':
      case 'www.yqt.life':
        icp = '豫ICP备15012682号-5'
        name = '最初的你好'
        break
      case '10000.wiki':
      case 'www.10000.wiki':
        icp = '豫ICP备15012682号-6'
        name = '最终的安好'
        pIcp = '41142502000133'
        break
      case '54188.fun':
      case 'www.54188.fun':
        icp = '豫ICP备15012682号-7'
        name = '一点夏沫'
        pIcp = '41142502000134'
        break

      default:
        break
    }
    this.data = {
      title: 'omi',
      song: [],
      keys,
      host,
      icp,
      name,
      pIcp
    }
    this.setSong(moon)
    document.title = name
  }
}

AppFooter.css =
  'body{margin:0}hr{margin:0 50px;border:0;border-top-color:currentcolor;border-top-style:none;border-top-width:0px;border-top:1px solid rgba(0,0,0,0.1)}hr{box-sizing:content-box;height:0;overflow:visible}.bg-yellow{background-color:#f8e8d5}.container{line-height:50px;height:50px;width:100%;margin-right:auto;margin-left:auto}.text-secondary{color:#6c757d !important}.text-center{text-align:center !important}.code{padding:0 250px}@media screen and (max-width: 1000px){.code{padding:0 20px}}code{overflow:hidden;background-color:rgba(0,0,0,0.1);width:100%;display:block;padding:10px 0}.do-center{text-align:center}.margin-right-8{margin-right:8px}'
AppFooter.use = [
  {
    count: 'count',
    song: 'song'
  }
]
define('app-footer', AppFooter)
